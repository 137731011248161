input:disabled {
  cursor: not-allowed;
  border-color: red;
}
.imageContainer {
  display: block;
  margin-left: auto;
  margin-right: auto;
  transition: transform 0.3s;
  cursor: auto !important;
  height: 100%;
  width: 100%;
}
.inputCls {
  border: 1px solid gray;
  padding: 1px 6px;
  /* width: 140px; */
  border-radius: 5px;
}
.check-dialog {
  cursor: pointer !important;
}
.inputCls:focus {
  border: 1px solid rgb(13, 158, 0);
  outline: none;
  /* border-color: #00bafa; */
}

label {
  font-size: 12px;
  margin: 0;
}

.form-fields {
  display: flex;
  align-content: center;
  justify-content: center;
}
.search-bar {
  border: 2px solid rgb(100, 100, 100);
  width: 13rem;
  overflow: hidden;
  height: 160;
}
.gutter {
  background-color: #eee;
  background-repeat: no-repeat;
  background-position: 50%;
}

.gutter.gutter-vertical {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAFAQMAAABo7865AAAABlBMVEVHcEzMzMzyAv2sAAAAAXRSTlMAQObYZgAAABBJREFUeF5jOAMEEAIEEFwAn3kMwcB6I2AAAAAASUVORK5CYII=");
  cursor: row-resize;
}
@media only screen and (max-width: 1500px) {
  .inputCls {
    width: 150px;
  }

  label {
    font-size: 12px;
  }
}
.entryFieldContainer {
  display: flex;
  align-items: flex-end;
}
.entryFieldItemContainer {
  display: flex;
  flex-direction: column;
}
.searchResultsContainer {
  display: flex;
  position: absolute;
  flex-direction: column;
}
.inputContainer {
  padding-left: 10px !important;
  padding-top: 5px !important;
}
.zoom {
  transform-origin: 0px 0px;
  transform: scale(1) translate(0px, 0px);
  scroll-behavior: smooth;
}
