.footerBottom {
  flex-direction: row !important;
}

.inputSquire {
  padding: 0.5em;
  -webkit-appearance: none;
  outline: 0.1em solid black;
  outline-offset: 0.1em;
}

.inputSquire:checked {
  display: inline-block;
  background-color: #000;
}
input:focus {
  outline: none;
}
@media (max-width: 1038px) {
  .footerBottom {
    flex-direction: column !important;
  }
}

.floatingTable::-webkit-scrollbar-thumb {
  border-radius: 0px;
  /* background-color: #ffbe33; */
  background-color: #61636a;
  box-shadow: #a7a7a7;
}
.floatingTable::-webkit-scrollbar-track {
  border-radius: 0px;
  /* background-color: #0f4b83; */
  background-color: #a7a7a7;
  box-shadow: #a7a7a7;
}
li:last-child {
  border-bottom: none;
}
